import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CityService } from '../../../services/city.service';
import { OrganizationService } from '../../../services/organization.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribePopUpComponent } from '../../../shared/components/subscribe-pop-up/subscribe-pop-up.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ThankyouComponent } from '../../../components/thankyou/thankyou.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutetracingService } from '../../../services/routetracing.service';
import { Meta, Title } from '@angular/platform-browser';
import e from 'express';
import { DarkModeService } from '../../../services/dark-mode.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-openaccount',
  templateUrl: './openaccount.component.html',
  styleUrl: './openaccount.component.css'
})
export class OpenaccountComponent {

  registrationForm: FormGroup | undefined;

  formDataToSend = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    customerID: '',
    country: '',
    companyName: '',
    city: '',
    postCode: '',
    address1: '',
    address2: '',
    isFreightForwarder: false
  };

  countries = this.cityService.getCountries();

  cities: any[] = [];

  // email: string | undefined;
  subscription: Subscription | null = null;
  cityCountry: boolean = false;
  loader: boolean = false;
  validcustomerID: boolean = true;
  loadingCities: boolean = false;
  paramsReceived: any;
  dialogRef: MatDialogRef<SubscribePopUpComponent> | null;
  megaMenu: boolean = false;
  constructor(private router: Router, public dialog: MatDialog, private formBuilder: FormBuilder, private http: HttpClient, private cityService: CityService, private organizationService: OrganizationService, private snackBar: MatSnackBar, private routeTracing: RoutetracingService, private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta, private darkModeService: DarkModeService) {
    this.dialogRef = null;
  }
  conexaLogo = '../../../../assets/logo/Conexa-blue-green.png';
  ngOnInit(): void {

    this.subscription = this.darkModeService.darkMode$.subscribe(isDarkMode => {
      if (isDarkMode) {
        this.conexaLogo = '../../../../assets/logo/Conexa-white.png';
      } else {
        this.conexaLogo = '../../../../assets/logo/Conexa-blue-green.png';
      }
    })


    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      customerID: [''],
      country: [''],
      companyName: [''],
      city: [''],
      postCode: [''],
      address1: ['', [Validators.maxLength(25)]],
      address2: ['', [Validators.maxLength(25)]],
      isFreightForwarder: [false]
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsReceived = params;
      if (this.paramsReceived.path === 'megaMenu') {
        this.megaMenu = true;
      } else if (this.paramsReceived.path === 'conexa') {
        this.megaMenu = false;
      }
    })

    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    this.title.setTitle(toCapitalize("Open An Account") + " - Intercargo Logistics");

    this.meta.removeTag('name="author"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    // this.meta.removeTag('property="og:image"');
    this.meta.addTags([
      { name: 'keywords', content: 'Logistics, cargo, air freight, sea freight' },
      { name: 'author', content: 'Intercargo Logistics' },
      { property: 'og:title', content: 'Open An Account' },
      {
        property: 'og:description', content: "Get ready to streamline your logistics with Conexa: Expertly delivered."
      },
      // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
    ]);

  }

  onCustomerIDFocusOut(): void {
    if (this.registrationForm!.get('customerID')!.value != '') {
      // Disable form fields if customerID is not an empty string
      this.registrationForm!.get('country')!.disable();
      this.registrationForm!.get('companyName')!.disable();
      this.registrationForm!.get('city')!.disable();
      this.registrationForm!.get('postCode')!.disable();
      this.registrationForm!.get('address1')!.disable();
      this.registrationForm!.get('address2')!.disable();
      this.registrationForm!.get('isFreightForwarder')!.disable();
      this.makeApiCall()
    } else {
      // Enable form fields if customerID is an empty string
      this.registrationForm!.get('country')!.enable();
      this.registrationForm!.get('companyName')!.enable();
      this.registrationForm!.get('city')!.enable();
      this.registrationForm!.get('postCode')!.enable();
      this.registrationForm!.get('address1')!.enable();
      this.registrationForm!.get('address2')!.enable();
      this.registrationForm!.get('isFreightForwarder')!.enable();
    }
  }

  dataReceived: any;
  makeApiCall(): void {
    this.loader = true;
    this.organizationService.getOrganizationDetails(this.registrationForm!.get('customerID')!.value).subscribe(
      (response) => {
        const data = response.data.native.body.organization[0].orgHeader;
        this.dataReceived = data;
        const cityValue = data.orgAddressCollection.orgAddress.city;

        this.placeholder = data.orgAddressCollection.orgAddress.city;
        let countryValue = data.orgAddressCollection.orgAddress.countryCode.code;
        this.cities = []
        if (cityValue && countryValue) {
          this.cityCountry = true;
          countryValue = this.countries.find(c => c.code === countryValue)?.name;
          this.registrationForm!.get('address1')!.setValue(data.orgAddressCollection.orgAddress.address1);
          this.registrationForm!.get('address2')!.setValue(data.orgAddressCollection.orgAddress.address2);
          this.registrationForm!.get('postCode')!.setValue(data.orgAddressCollection.orgAddress.postCode);
          // this.registrationForm!.get('city')!.setValue(cityValue);
          // this.cities.push(cityValue);
          this.registrationForm!.get('country')!.setValue(countryValue);
          this.registrationForm!.get('companyName')!.setValue(data.fullName);
          this.registrationForm!.get('isFreightForwarder')!.setValue((data.isForwarder === 'true'));

          this.formDataToSend = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            customerID: this.registrationForm!.get('customerID')!.value || '',
            country: this.registrationForm!.get('country')!.value || '',
            companyName: this.registrationForm!.get('companyName')!.value || '',
            city: this.registrationForm!.get('city')!.value || '',
            postCode: this.registrationForm!.get('postCode')!.value || '',
            address1: this.registrationForm!.get('address1')!.value || '',
            address2: this.registrationForm!.get('address2')!.value || '',
            isFreightForwarder: (this.registrationForm!.get('isFreightForwarder')!.value === 'true')
          };
        }

        // this.disableSetFields();
        // this.enableRemainingFields();
        this.validcustomerID = true
        this.loader = false;

      },
      (error) => {
        this.validcustomerID = false
        this.loader = false;
        // this.registrationForm!.get('country')!.enable();
        // this.registrationForm!.get('companyName')!.enable();
        // this.registrationForm!.get('city')!.enable();
        // this.registrationForm!.get('postCode')!.enable();
        // this.registrationForm!.get('address1')!.enable();
        // this.registrationForm!.get('address2')!.enable();
        // this.registrationForm!.get('isFreightForwarder')!.enable();

      }
    );
  }
  placeholder = 'Select City';
  onCountryChange(event: Event) {
    this.cities = [];
    this.registrationForm!.get('city')!.setValue('');
    if (this.registrationForm!.get('customerID')!.value == '') {
      // if (country) {
      this.loadingCities = true;
      this.registrationForm!.get('city')!.disable();
      this.placeholder = 'Select City';
      const selectElement = event.target as HTMLSelectElement;
      const selectedValue = selectElement.value;
      this.fetchCities(selectedValue);
    }
  }

  fetchCities(country: string): void {
    const countries = this.cityService.getCountries();
    const countrycode = countries.find(c => c.name === country)?.code;
    this.cityService.getCities(countrycode).subscribe(
      (response) => {
        this.cities = response; // Adjust according to API response structure
        this.filteredCities = this.cities;
        this.cities.unshift({ name: 'Select City', code: '', timezone: '' });
        this.placeholder = '';
        this.registrationForm!.get('city')?.setValue(this.filteredCities[0].name);

        this.loadingCities = false;
        this.registrationForm!.get('city')!.enable();
      },
      (error) => {
      }
    );
  }
  cityError: boolean = false;

  onSubmit(): void {
    if (this.placeholder.includes('Select City')) {

      this.cityError = true;
      return;
    }
    if (this.registrationForm!.invalid) {

      this.registrationForm!.markAllAsTouched();
      return;
    }
    if (this.registrationForm!.valid) {
      this.cityError = false;
      const formData = this.registrationForm!.value;

      if (formData.customerID === '') {
        this.formDataToSend.customerID = '';
        this.formDataToSend.firstName = formData.firstName;
        this.formDataToSend.lastName = formData.lastName;
        this.formDataToSend.email = formData.email;
        this.formDataToSend.phone = formData.phone;
        this.formDataToSend.address1 = formData.address1;
        this.formDataToSend.address2 = formData.address2;
        this.formDataToSend.postCode = formData.postCode;
        this.formDataToSend.city = formData.city;
        var countrycode = this.countries.find(c => c.name === formData.country)?.code;
        this.formDataToSend.country = countrycode ?? '';
        this.formDataToSend.companyName = formData.companyName;
        this.formDataToSend.isFreightForwarder = formData.isFreightForwarder.toString();
      }
      else {
        this.formDataToSend.firstName = formData.firstName;
        this.formDataToSend.lastName = formData.lastName;
        this.formDataToSend.email = formData.email;
        this.formDataToSend.phone = formData.phone;
        this.formDataToSend.customerID = formData.customerID;
        const countryValue = this.registrationForm!.get('country')?.value ?? 'defaultCountry';
        this.formDataToSend.city = this.dataReceived?.orgAddressCollection?.orgAddress?.city ?? this.selectedCity.code;
        var countrycode = this.countries.find(c => c.name === countryValue)?.code;
        this.formDataToSend.country = countrycode ?? '';
      }
      let apiData: any;
      if (this.megaMenu) {
        apiData = {
          Orgcode: this.formDataToSend.customerID,
          orgName: this.formDataToSend.companyName,
          firstName: this.formDataToSend.firstName,
          lastName: this.formDataToSend.lastName,
          phone: this.formDataToSend.phone,
          email: this.formDataToSend.email,
          address1: this.formDataToSend.address1,
          address2: this.formDataToSend.address2,
          postCode: this.formDataToSend.postCode,
          city: this.formDataToSend.city,
          countrycode: this.formDataToSend.country,
          isForwarder: this.formDataToSend.isFreightForwarder.toString(),
          unloco: this.selectedCity.code,
          page: "company-registration",
          eventCode: "NCA"
        };
      }
      else {
        apiData = {
          Orgcode: this.formDataToSend.customerID,
          orgName: this.formDataToSend.companyName,
          firstName: this.formDataToSend.firstName,
          lastName: this.formDataToSend.lastName,
          phone: this.formDataToSend.phone,
          email: this.formDataToSend.email,
          address1: this.formDataToSend.address1,
          address2: this.formDataToSend.address2,
          postCode: this.formDataToSend.postCode,
          city: this.formDataToSend.city,
          countrycode: this.formDataToSend.country,
          isForwarder: this.formDataToSend.isFreightForwarder.toString(),
          unloco: this.dataReceived?.closestPort?.code ?? this.selectedCity.code,
          page: 'conexa-signup',
          eventCode: "CR"
        };
      }



      this.loader = true;

      this.organizationService.submitOrganizationData(apiData).subscribe(
        (response) => {
          this.loader = false;
          this.routeTracing.setParamValue(this.paramsReceived)
          if (response.message.includes('Contact with same email address already Exist')) {
            this.snackBar.open('Contact with same Email Address already exists and Oppurtunity has been updated', 'Success', {
              duration: 8000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
              panelClass: ['snackbar-success'],
            });
          }
          this.router.navigate([{ outlets: { modal: 'conexathankyou' } }])
        },
        (err) => {
          this.loader = false;
          this.snackBar.open(err.error.message, err.error.status, {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['snackbar-error'],
          });
        }
      );
    }
  }

  onCancel(): void {
    // Handle cancel action if needed
  }

  filteredCities: any[] = [];

  onCityInput(event: any): void {
    const inputValue = event.target.value.toLowerCase();
    // Filter cities based on input
    this.filteredCities = this.cities.filter(city =>
      city.name.toLowerCase().includes(inputValue)
    );

    // Update the form control value to reflect the input
    this.registrationForm!.get('city')?.setValue(inputValue);
  }

  isPlaceholderVisible = true; // Track placeholder visibility



  onSearch(term: any) {
    // When the user starts typing, hide the placeholder
    if (term.term.length > 0) {
      this.placeholder = '';
    } else {
      this.placeholder = 'Select City';
    }
    this.togglePlaceholder();
  }

  onFocus() {
    this.togglePlaceholder();
  }

  onBlur() {
    this.togglePlaceholder();
  }

  togglePlaceholder() {
    // Use the CSS class to hide/show the placeholder dynamically
    const ngSelectElem = document.querySelector('.ng-select');
    if (ngSelectElem) {
      if (this.isPlaceholderVisible) {
        ngSelectElem.classList.remove('ng-placeholder-hidden');
      } else {
        ngSelectElem.classList.add('ng-placeholder-hidden');
      }
    }
  }
  selectedCity: any;
  onCityChange(event: any): void {
    // Get the selected city by matching the 'code' property
    this.selectedCity = this.cities.find(city => city === event);


    // Placeholder logic - hide placeholder when the city is selected
    if (event !== 'Select City' || event !== '') {
      this.placeholder = '';

    } else {
      this.placeholder = 'Select City';
    }

    // Update the form control with the selected city
    if (this.selectedCity) {
      this.registrationForm!.get('city')?.setValue(this.selectedCity.name);
    }
  }


  openModal() {
    const dialogRef = this.dialog.open(ThankyouComponent, {
      width: '800px',
      maxHeight: '500px',
      // height: '95%',
      // panelClass: 'email-container',
      data: {
        btnTxt: 'Subscribe',
        heading: 'Stay Ahead of Intelligent Supply Chain with Our Newsletter',
        imgUrl: '../../../../assets/imgs/Subscribe.png',
        thanksTxt: 'You have successfully subscribed to our newsletter. Welcome to Intercargo community! Expect regular updates, exclusive content, and the latest news straight to your inbox. ',
        thanksHeading: `Thank You for <span class='text-success'> Subscribing! 🎉</span>`,
        email: false,

      },
      disableClose: true, // Optional: prevents closing the dialog by clicking outside or pressing ESC
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      this.router.navigate([''])
    });
  }

}
interface OrganizationResponse {
  native: {
    body: {
      organization: Array<{
        orgHeader: any; // Define the correct type based on your API response
      }>;
    };
  };
}

interface ApiResponse {
  data: OrganizationResponse;
}
